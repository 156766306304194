.custom-form {
    margin-bottom: 6px;
    position: relative;
    /* border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.14) 0px 8px 16px, rgba(0, 0, 0, 0.12) 0px 0px 2px; */
}

.custom-form .suggestion-search .p-autocomplete-input {
    margin-right: 0 !important;
}

.custom-form .form-control,
.custom-form .suggestion-search .p-autocomplete-input {
    border-radius: 5px;
    border: 1px solid #B4C5DD;
    background: #FFF;
    color: rgba(96, 96, 96, 0.80);
    font-size: 14px;
    font-weight: 400;
    height: 34px;
    align-items: center;
    display: flex;
    margin-right: 10px;
    max-width: 215px;
    margin-bottom: 5px;
    /* Changes Made  */
}

.p-multiselect .p-multiselect-label {
    padding: 13px !important;
}

.firstname-input {
    max-width: 100% !important;
    padding: 0 0 0 40px !important;
}

.custom-form .form-control .p-dropdown-label {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0;
    font-size: 14px;
}

/* @media (min-width: 1700px) and (max-width: 1800px) {

    .custom-form .form-control,
    .custom-form .suggestion-search .p-autocomplete-input {
        max-width: 213px;
        margin-bottom: 10px;
    }
} */
.custom-date.form-control {
    max-width: 100%;
}

.custom-date .p-button {
    background-color: #0a3e71;
    border: none;
}

@media (max-width:1899px) {

    .custom-form .show-filterlist .form-control,
    .custom-form .show-filterlist .suggestion-search .p-autocomplete-input {
        max-width: 194px;
        margin-bottom: 10px;
    }

    .expiring-filter .custom-form .show-filterlist .form-control,
    .expiring-filter .custom-form .show-filterlist .suggestion-search .p-autocomplete-input {
        max-width: 200px;

    }
}

@media (max-width:1499px) {

    .expiring-filter .custom-form .show-filterlist .form-control,
    .expiring-filter .custom-form .show-filterlist .suggestion-search .p-autocomplete-input {
        max-width: 160px;
    }
}

@media (max-width:991px) {

    .custom-form .show-filterlist .form-control,
    .custom-form .show-filterlist .suggestion-search .p-autocomplete-input {
        max-width: 156px;
        margin-bottom: 10px;
    }
}

@media (max-width:767px) {

    .custom-form .show-filterlist .form-control,
    .custom-form .show-filterlist .suggestion-search .p-autocomplete-input {
        max-width: 143px;
        margin-bottom: 10px;
    }
}

@media (max-width:676px) {

    .custom-form .show-filterlist .form-control,
    .custom-form .show-filterlist .suggestion-search .p-autocomplete-input {
        max-width: 30.33%;
        margin-bottom: 10px;

    }

    /* .show-filterlist {
        justify-content: space-between;
    } */

    .advanced-filter-button {
        margin-bottom: 0 !important;
    }
}

@media (max-width:425px) {

    .custom-form .show-filterlist .form-control,
    .custom-form .show-filterlist .suggestion-search .p-autocomplete-input {
        max-width: 99%;
        /* max-width: 49%; */
        margin-bottom: 10px;
        margin-right: 0;
    }

    .show-filterlist {
        justify-content: space-between;
        max-height: 203px;
        overflow-y: auto;
    }

    .advanced-filter-button {
        margin-bottom: 0 !important;
    }

    .p-multiselect-panel {max-height: 125px !important;}
    .p-multiselect-items-wrapper {
        overflow: hidden;
    }

    
}