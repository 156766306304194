.p-button.p-button-outlined {
    border-radius: 4px !important;
    border: 1px solid #B4C5DD !important;
    color: #1B3D6D !important;
    height: 34px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 7px 10px !important;
    background-color: transparent !important;
    transition: all 0.4s ease !important;
}

.p-button.p-button-outlined:hover {
    background-color: #1B3D6D !important;
    color: #F5F5F5 !important;
}



.p-datatable-thead{
    background-color:#B4C5DD !important;
    color: #374151;
    font-weight: 700;
    font-size: 18px;
    
}
.def-marg {
    margin-top: 28px !important;

}

.fadein .animation-duration-500 .box .def-marg{
    margin-top: 20px    ;
}

.new-start-filter {
    display: flex;
    flex-wrap: wrap;  /* Allows items to wrap onto the next line */
    margin-top: 10px;
  }
  
/* .new-start-filte .newstartfilter{
    width:300px;
    padding-left: 0px;
} */

.new-start-filter .p-multiselect {

  width: 300px;  /* Set all inputs to have the same width */
  padding-left: 0px;  /* If needed */
}
