.chat-title {
    /* border: 1px solid #B4C5DD !important; */
    background: #FFF;
    overflow: hidden;
    /* border-top-right-radius: 20px !important;
    border-top-left-radius: 20px !important; */
    /* border-radius: 20px !important; */
}

.chat-title .p-dialog-header {
    display: flex;
    align-items: center;
    padding: 8px 6px !important;
    border-bottom: 1px solid #B4C5DD !important;
    /* margin-bottom: 45px; */
}

.chat-title .p-dialog-header h3 {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Josefin Sans', sans-serif;

}

.chat-title .p-dialog-title {
    display: flex;
    align-items: center;
}

.chat-title .p-dialog-content {
    padding: 15px 15px 20px 15px !important;
    background-color: #F5F5F5 !important;
}

.send-msg .form-control {
    color: #606060;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    border-radius: 15px;
    border: 0;
    padding: 9px 10px 8px 10px;
    width: calc(100% - 100px) !important;
}

.custom-chat {
    min-height: 37px !important;
    max-height: 115px !important;
    overflow-y: scroll !important;
}

.custom-chat:focus {
    box-shadow: none !important;
    border-color: transparent !important;
}

.send-msg .form-control:focus {
    box-shadow: none;
}

.send-msg {
    position: relative;
    /* border-radius: 15px; */
    border-top: 1px solid #B4C5DD;
}

.chat-details {
    font-size: 12px;
    color: #808080;
    margin-bottom: 10px;
}

.send-msg .pin-img {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
}
/* 

sending message box */
.chat-box p {
    border-radius: 20px 20px 0px 20px;
    /* border: 1px solid #BAD0E9; */
    /* background: #F5F5F5; */
    background: #dde4ef;
    /* color: #1F1F1F; */
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
    padding: 3px 7px;
    margin: 0px 0 4px;
    line-break: anywhere;
}

.right-chat p {
    border-radius: 20px 20px 20px 0;
    background-color: hsla(215, 60%, 27%, 1);
    padding: 8px 12px;
    color: white;
    /* background: hsla(215, 60%, 27%, 1); */
    border: 1px solid #1B3D6D;
    /* background: linear-gradient(270deg, hsla(215, 60%, 27%, 1) 0%, hsla(354, 71%, 44%, 1) 100%);

    background: -moz-linear-gradient(270deg, hsla(215, 60%, 27%, 1) 0%, hsla(354, 71%, 44%, 1) 100%);

    background: -webkit-linear-gradient(270deg, hsla(215, 60%, 27%, 1) 0%, hsla(354, 71%, 44%, 1) 100%); */
    /* background-image: linear-gradient(to left, #bf202f, #ab1557, #842b72, #523979, #1b3c6d); */
    /* color: #1B3D6D; */
    /* opacity: 0.8; */
}

.custom-divider.p-divider.p-divider-horizontal:before {
    display: none;
}

.custom-divider .p-divider-content {
    background-color: #F5F5F5 !important;
}

.custom-divider.p-divider.p-divider-horizontal {
    margin: 0;
}

.username.left-user {
    margin-left: 0;
    margin-right: 8px;
}

.chat-box {
    font-family: 'Josefin Sans', sans-serif;
    scrollbar-width: thin;
    overflow-x: hidden;
}

.custom-chat::-webkit-scrollbar {
    display: none;
}

.p-dialog-content::-webkit-scrollbar {
    width: 5px;
}

.p-dialog-content::-webkit-scrollbar-thumb {
    background-color: #1B3D6D;
    border-radius: 6px;
}


.p-dialog-content::-webkit-scrollbar-track {
    background-color: #ebeff4;
}

.odd .chat-box p {
    background: rgba(27, 61, 109, 0.10);
}

.p-dialog-header-icon svg {
    width: 22px;
    height: 22px;
}

.p-dialog-header-icon svg path {
    fill: #1B3D6D;
}

.p-dialog-footer {
    padding: 0 !important;
}

.p-dialog {
    /* min-height: 51% !important;
    max-height: 51% !important; */
    border-radius: 10px !important;
}

.chat-title {
    min-height: 51% !important;
    max-height: 51% !important;
    border-radius: 10px !important;
}

.p-dialog .p-dialog-header-icons {
    align-self: center !important;
}

.custom-start {
    clear: both;
    float: left;
    max-width: 93%;
    width: auto;
    color: #1F1F1F;
}

/* .custom-start p::after {
    position: absolute;
    content: '';
    border-bottom-color: red !important;
    left: 93%;
    bottom: 100%;
    left: 7%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: #F5F5F5;
    border-width: 8px;

} */

.custom-end {
    float: right;
    clear: both;
    max-width: 90%;
    width: auto;
    color: #1B3D6D;


}


.p-divider .p-divider-content {
    background-color: #F5F5F5 !important;
}

.custom-end span {
    text-align: end;
}

.send-icon {
    transform: translate(0, -50%);
}

.custom-end p {
    background: hsla(215, 60%, 27%, 1);

    /* background: linear-gradient(270deg, hsla(215, 60%, 27%, 1) 0%, hsla(354, 71%, 44%, 1) 100%);

background: -moz-linear-gradient(270deg, hsla(215, 60%, 27%, 1) 0%, hsla(354, 71%, 44%, 1) 100%);

background: -webkit-linear-gradient(270deg, hsla(215, 60%, 27%, 1) 0%, hsla(354, 71%, 44%, 1) 100%);
background-image: linear-gradient(to left, #bf202f, #ab1557, #842b72, #523979, #1b3c6d); */
    /* background: #1b3c6d; */
    color: #fff;
    /* background: rgba(27, 61, 109, 0.10); */
}

.p-dialog-header-close {
    box-shadow: none !important;
}

.username {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    min-width: 22px;
    min-height: 22px;
    background: hsla(215, 60%, 27%, 1);
    /* background: linear-gradient(270deg, hsla(215, 60%, 27%, 1) 0%, hsla(354, 71%, 44%, 1) 100%);

    background: -moz-linear-gradient(270deg, hsla(215, 60%, 27%, 1) 0%, hsla(354, 71%, 44%, 1) 100%);

    background: -webkit-linear-gradient(270deg, hsla(215, 60%, 27%, 1) 0%, hsla(354, 71%, 44%, 1) 100%);
    background-image: linear-gradient(to left, #bf202f, #ab1557, #842b72, #523979, #1b3c6d); */

    /* opacity: 0.8; */
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    font-size: 10px;
    line-height: 10px;
    position: relative;
    top: -10px;
    padding: 2px 0 0 0;
}

.inner-chat {
    text-align: right;
}

/* sending box box style  */
.inner-chat p {
    text-align: left;
    position: relative;
    padding-right: 12px;
    /* box-shadow: 0px 3px 66px rgba(0,0,0,0.0588235294);   */
    box-shadow: 0px 3px 66px rgba(0, 0, 0, 0.0588235294);
    /* line-break: normal !important; */
}

.left-messages.inner-chat p::before {
    position: absolute;
    content: "";
    bottom: -1px;
    left: -8px;
    position: absolute;
    background: transparent;
    height: 0px;
    width: 0px;
    border-bottom: 20px solid hsla(215, 60%, 27%, 1);
    border-left: 20px solid transparent;
}

.right-messages.inner-chat p::before {
    content: "";
    bottom: 0px;
    right: -7px;
    position: absolute;
    background: transparent;
    height: 0px;
    width: 0px;
    border-bottom: 20px solid hsla(215, 60%, 27%, 1);
    border-right: 20px solid transparent;
}

.inner-chat.left-messages {
    text-align: left;
}

/* Directly apply custom width/height with !important */
/* Basic dialog styling for mobile and desktop */
.assigndialog .p-dialog-content {
    padding: 0.5rem;
  }
  
/* Dialog style adjustments */
.assign-dialog .p-dialog-content {
    padding: 0.5rem;
  }
  
  /* Button hover effect */
  .button-hover {
    transition: all 0.3s ease;
  }
  
  .button-hover:hover {
    color: white;
    background-color: #1B3D6D;
  }
  .assign-modal .modal-backdrop {
    background: transparent !important;
}


/* for reciving message */
.message-container {
    display: flex;
    flex-direction: column; 
    gap: 10px; 
    align-items: flex-start;  
    width: 209%;
    overflow: hidden;  
    padding:5px 5px;
  }
  

  p.main-p-download {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    max-width: 50%;
    word-wrap: break-word;
    white-space: normal;
    text-align: left !important;  /* Align text inside the container */
    padding: 6px 15px;
    margin: 0;
    position: relative;
    flex: 0 1 auto;
  }
  
  p.main-p-download > * {
    position: absolute;  /* Still using absolute positioning */
    top: 0;  /* Adjust these values to control the position */
    left: 0;  /* Ensure the child is aligned from the left of the container */
    text-align: left; /* Text inside absolute elements will also be aligned to the left */
  }
  
  



@media (max-width:767px) {
    .chat-title .p-dialog-content {
        padding: 10px 8px 10px 8px !important;
    }
    .download-icon .custom-target-icon.download-icon {
        font-size: 16px !important; /* Smaller icon size */
        padding: 4px !important; 
    }
    p.main-p-download{
        /* max-height: 70px !important; */
        /* max-width: 100px !important; */
        max-width: 15rem !important;

        text-align: left;

    }

    .message-container{
        width: 221%;
    }
    .inner-chat.left-messages p svg{
 margin-top: -111px !important;
    }
    /* .add-to-chart-link{left: 79px !important;
        bottom: 120px !important;
    } */

    .p-dialog {
        min-height: 65% !important;
        max-height: 65% !important;
        margin: 10px;
    }
}

@media (max-width: 496px) {    
    .inner-chat.left-messages p svg {
        width: 56px !important;
        margin-top: 100px !important;
        height: 59px;
        text-align: center;
        margin-left: 12px;
    }

  
    


    .inner-chat.left-messages p svg {
        margin-top: 2px !important;

    }
    .add-to-chart-link {
        left: 79px !important;
        bottom: 10px !important;
    }

    
}


@media (min-width: 497px)and (max-width:767px){

    .inner-chat.left-messages p svg {
        width: 56px !important;
        margin-top: 100px !important;
        height: 59px;
        text-align: center;
        margin-left: 12px;
    }

    .inner-chat.left-messages p svg {
        margin-top: 2px !important;

    }
    .add-to-chart-link {
        left: 79px !important;
        bottom: 10px !important;
    }

}

/* download left chat  ------end*/
.p-dialog .p-dialog-footer button {
    height: 37px;
}

.like-block {
    position: relative;
}

.like-block .thum-reaction {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 0;
    border-radius: 6px;
    text-align: center;
    left: 4px;

}

.like-block:hover .thum-reaction {
    opacity: 1;
    visibility: visible;
}

.like-block .like-click {
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    bottom: 10px;
    right: 40px;
    text-align: center;
    width: 22px;
    height: 22px;
}

.like-block.right-like-block .thum-reaction {
    right: 20px;
    left: unset;
}

.like-block.right-like-block .like-click {
    left: 40px;
    bottom: unset;
}


.download-icon.custom-icon-width {
    width: 50px;
    height: 50px;
    color: #fff;
    text-align: center;
}
.add-to-chart-link {
    color: blue;
    cursor: pointer;
    text-decoration: underline;
    margin-left: 10px; /* Adjust the spacing as needed */
  }
  



  .button-hover {
    margin-right: 90px;
    width: 100px;
    color: black !important;
    background-color: white !important;
    transition: all 0.3s ease;
  }
  
  /* Hover effect */
  .button-hover:hover {
    color: white !important;
    background-color: #1B3D6D !important;
  }
  