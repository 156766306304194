.custom-header .nav-item {
    font-size: 26px;
    font-weight: 600;
    color: #000;
    text-decoration: none;
}

/* .custom-header .navbar-text{
   border-left: 1px solid #E3E9F1;
} */

.custom-header {
    background-color: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    position: fixed !important;
    top: 0;
    width: 100%;
    z-index: 999;
}

.user-name h5 {
    color: #1F1F1F;
    font-size: 16px;
    font-weight: 700;
}

.user-name span {
    color: #606060;
    font-size: 13px;
    font-weight: 400;
}

.custom-header .avatar {
    margin-left: 14px;

}

.user-name {
    margin-left: 14px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
}

.navbar-toggler-icon {
    background-image: url('../images/mobile-menu.svg') !important;
    position: relative;
    z-index: 999;
}

.custom-header .mobile-menu {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    box-shadow: none;
}

.navbar-collapse {
    height: unset !important;
}

.mobilemenu a {
    border-radius: 0;
}

@media (max-width:991px) {
    .navbar-brand {
        width: 90px;
    }

    .content {
        padding: 80px 0px 0 0px;
    }

    .custom-header .container-fluid {
        display: flex;
        align-items: center;
    }

    .custom-header a {
        font-size: 17px;
        padding: 0 20px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-decoration: none;
        flex-direction: row-reverse;
        margin: 0;
    }

    /* .navbar-nav {
        padding: 20px 0;
    } */

    .custom-header .navbar-collapse img {
        width: 23px;
        height: 23px;
    }

    .navbar-collapse {
        position: absolute;
        width: 250px;
        background-color: #204172;
        position: absolute;
        top: 60px;
        right: -250px;
        margin: 0;
        text-align: left;
        z-index: 99;
        height: 100%;
        transition: .2s ease;

    }

    .navbar-collapse.show {
        right: 0;
        height: 100vh !important;
    }

    .custom-header .navbar-collapse a img {
        filter: brightness(0) invert(1);
    }

    .navbar-toggler:focus {
        box-shadow: none !important;
    }

    .custom-header a {
        padding: 5px 10px;
        border-bottom: 1px solid #fff;
    }

    .custom-header a:last-child {
        border-bottom: none;
    }

}
