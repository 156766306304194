.sidebar-shape {
    position: fixed;
    top: 100px;
    left: 10px;
    bottom: 10px;
    width: 100px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.14) 0px 8px 16px, rgba(0, 0, 0, 0.12) 0px 0px 2px;
    overflow-y: auto;
    z-index: 9;
}

.sidebar-shape::-webkit-scrollbar {
    width: 0;
}



.menu-list a {
    border-radius: 8px;
    /* background-color: #0a3e71; */
    padding: 0 8px;
    margin: 8px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* margin-bottom: 30px; */
    text-decoration: none;
    color: #495057;
    position: relative;
    transition: all .4s ease 0s;
}

.menu-list a .img-active::after {
    position: absolute;
    content: '';
    background-color: #0a3e71;
    right: -30px;
    bottom: 0;
    width: 5px;
    height: 0;
    opacity: 0;
    visibility: hidden;
    border-radius: 6px;
    transition: all 0.35s ease;
}

.menu-list a.active .img-active::after,
.menu-list a:hover .img-active::after {
    height: 100%;
    opacity: 1;
    visibility: visible;
}

.menu-list a .img-active {
    background-color: transparent;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 9px;
    position: relative;

}

.menu-list a.active .img-active,
.menu-list a:hover .img-active {
    background-color: #0a3e71;
    transition: all .4s ease 0s;
}



.menu-list a.active img,
.menu-list a:hover img {
    filter: brightness(0) invert(1);
}

/* .menu-list li a {
    display: inline-block;
    margin-bottom: 10px;
} */

/* .sidebar-shape .menu-list a {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 30px;
} */

.sidebar-shape .menu-list span {
    text-align: center;
    font-size: 14px;
    margin-top: 5px;
}

.sidebar-shape .menu-list {
    position: absolute;
    top: 0;
    left: 0;
    padding: 30px 0;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
}

.menu-list li:active {
    background-color: #B4C5DD;
}

.custom-width-height {
    width: 26px;
    height: 26px;
}

.p-paginator {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    border-width: 0 !important;
    border: none !important;
}

/* .w-66 {
    width: 66%;
} */
.not-found-container {
    display: flex;
}

.sidebar {
    width: 250px;
}

.not-found-content {
    flex-grow: 1;
    padding: 20px;
    text-align: center;
}