.content {
    padding: 100px 0px 100px 0px;
}

.p-datatable-wrapper::-webkit-scrollbar {
    width: 6px !important;
    height: 6px !important;
}

.p-datatable-wrapper::-webkit-scrollbar-track {
    background: transparent !important;
    border: 2px solid #c3c3c3 !important;
}

.p-datatable-wrapper::-webkit-scrollbar-thumb {
    background-color: #c3c3c3 !important;
    border-radius: 20px !important;
    border: 3px solid #c3c3c3 !important;
}

.custom-table .p-datatable-thead tr {
    border-radius: 9px;
    background: rgba(118, 139, 167, 0.10);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-spacing: 5px;
}

.p-datatable .p-column-header-content {
    display: flex;
    align-items: center;
    color: #1F1F1F;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Josefin Sans', sans-serif;
    white-space: nowrap;
}

.p-datatable .p-sortable-column:last-child .p-column-header-content {
    padding-right: 50px !important;
}

.p-datatable-table .p-datatable-thead tr {
    border-radius: 9px;
    background: rgba(118, 139, 167, 0.10);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-spacing: 5px;
    position: relative;
    z-index: 99;
}

.p-datatable .p-datatable-tbody>tr>td {
    font-size: 16px;
    font-weight: 400;
    font-family: 'Josefin Sans', sans-serif;
    border-width: 0 0 0 0 !important;
}

.p-datatable .p-datatable-thead>tr>th,
.p-datatable .p-datatable-tbody>tr>td {
    border-width: 0 0 0 0 !important;
    padding: 10px 10px 10px 10px !important;
}

.p-datatable .p-datatable-tbody>tr>td {
    /* padding: 15px 38px 15px 38px !important;    */
    /* Changes Made */
    white-space: nowrap;
}

.p-datatable .p-datatable-thead>tr>th:first-child {
    border-radius: 9px 0px 0 9px;
}

.p-datatable .p-datatable-thead>tr>th:last-child {
    border-radius: 0px 9px 9px 0;
    padding: 5px 30px 5px 10px !important;
}

/* modal desgin */
.modal-title {
    color: #1F1F1F;
    font-size: 16px;
    font-weight: 700;
}

.custom-checkbox label,
.taskdata {
    color: #454545;
    font-size: 13px;
    font-weight: 400;
    line-height: 13px;
}

.check-list {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
}

.check-list .p-checkbox-box {
    border: 1px solid #2d5082 !important;
}

.check-list .p-highlight {
    background: #2d5082 !important;
}

.modal-title {
    width: 100%;
}

.modal .modal-dialog {
    max-width: 850px;
}

/* custom-checkbox desgin  */


/* .p-checkbox {
    position: relative;
    cursor: pointer;
  }
  
   .p-checkbox:before {
    content:'';
    appearance: none;
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #0079bf;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
  }
  
   input:checked + .p-checkbox:after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 9px;
    width: 6px;
    height: 14px;
    border: solid #0079bf;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  } */
.p-datatable-table {
    width: 100%;
}

.dots {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    /* background-color: red; */
}

@media (max-width:767px) {

    .p-datatable .p-datatable-tbody>tr>td,
    .p-datatable .p-datatable-thead>tr>th {
        padding: 10px !important;
        white-space: nowrap;
    }

    .checkbox-group {
        font-size: 14px;
        margin-bottom: 15px;
        border-bottom: 1px solid grey;
    }

    .checkbox-group:last-child {
        border-bottom: none;
    }

    .check-list {
        margin: 0;
    }

    .p-checkbox .p-checkbox-box {
        width: 15px;
        height: 15px;
    }
}

.task .p-column-title {
    /* Changes Made */
    margin-left: 13px;
}

.modal-footer button {
    height: 38px;
}

.task .p-column-title {
    margin-left: 33px;
}

.modal-footer button {
    height: 38px;
}

.custom-scroll::-webkit-scrollbar {
    width: 6px !important;
}

.custom-scroll::-webkit-scrollbar-thumb {
    background-color: #707070 !important;
    border: 3px solid #707070 !important;
    border-radius: 20px !important;
}

.custom-scroll::-webkit-scrollbar-track {
    background: 0 0 !important;
    border: 2px solid #707070 !important;
}