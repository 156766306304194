@import '../../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import "../../../node_modules/@syncfusion/ej2-pdfviewer/styles/material.css";


body {
    font-family: 'Josefin Sans', sans-serif;
    background-color: #fff;
    overflow-y: hidden;
}



/* a {
    text-decoration: none;
} */

.custom-container {
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    height: 100vh;
}

.primary-color {
    background-color: #1B3D6D !important;
}

.common-btn {
    border-radius: 4px;
    background: #1B3D6D;
    color: #F5F5F5 !important;
    text-align: center;
    font-size: 16px;
    line-height: 16px;
    padding: 7px 10px;
    font-weight: 400;
    border: 1px solid transparent;
    display: inline-block;
    transition: all 0.1s ease;
}

.btn {
    height: 32px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-button {
    border-radius: 4px;
    border: 1px solid #B4C5DD;
    color: #1B3D6D;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: pre;
    padding: 7px 10px;

}

.custom-button:hover {
    background: #1B3D6D;
    color: #F5F5F5;
    transition: all .4s ease 0s;
}

.custom-link {
    color: #495057;
}

.dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
}

.success {
    background-color: #198754 !important;
}

.warning {
    background-color: #ffc107 !important;
}

.custom-tooltip {
    background-color: transparent;
    box-shadow: none;
}

/* .p-multiselect-header {
    display: none !important;
} */
/* .p-multiselect-items-wrapper {
    max-height: unset !important;
} */

.p-tooltip.p-tooltip-top,
.p-tooltip.p-tooltip-bottom {
    padding: 0 !important;
}

.p-tooltip-bottom .p-tooltip-arrow {
    top: -4px !important;
}

.p-tooltip .p-tooltip-text {
    background: #234576 !important;
    box-shadow: none !important;
    font-size: 12px !important;
}

.sidebar {
    max-width: 90px;
}

.main-content {
    max-width: calc(100% - 110px);
    width: 100%;
}

.custom-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}



.p-dialog .p-dialog-header-icons {
    align-self: center;
}

.p-overlay-badge .custom-badge {
    position: absolute;
    top: 5px !important;
    right: 3px !important;
    border-radius: 50%;
    background-color: #EF4444 !important;
    width: 15px !important;
    height: 15px !important;
    min-width: unset !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.p-overlay-badge .dots {
    position: absolute;
    top: 3px !important;
    /* right: 3px !important; */
    left: 12px !important;
    border-radius: 50%;
    background-color: #EF4444 !important;
    width: 13px !important;
    height: 13px !important;
    min-width: unset !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border: 2px solid #fff !important;
}

.last-unread-message {
    font-weight: bold;
    color: red;
    /* or any other styling to make it stand out */
}

#last_read_message {
    background-color: yellow !important;
    ;
}


@media (max-width:991px) {
    .main-content {
        max-width: 100%;
    }
    .modal .modal-dialog.updatetask-modal {
        max-width: 100%;
        width: auto;
       
    }
}

@media (max-width:767px) {
    .custom-spinner {
        width: 250px;
    }
}



/* .p-tooltip.p-tooltip-top .p-tooltip-arrow {
    border-top-color: #fff !important;
}
.p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
    border-bottom-color: #fff !important;
} */

.custom-padding {
    margin-top: 85px;
}

.p-multiselect-panel {
    width: 15px;
}

/* .advanced-filter-button {
    transition: all 0.1s ease;
} */

.p-datatable .p-sortable-column .p-sortable-column-icon {
    width: 0.6rem !important;
}

/* @media (max-width : 1855px) {
    .card {
        margin-top: 69px;
    }

    .position-absolute .common-btn {
        margin-top: 4px;
    }
}

@media (max-width : 1771px) {
    .flex-wrap {
        width: 100%;
    }
}

@media (max-width : 1746px) {
    .flex-wrap {
        width: 100%;
    }
}

@media (max-width : 1399px) {
    .card {
        margin-top: 65px;
    }
}

@media (max-width : 1199px) {
    .card {
        margin-top: 73px;
    }
}

@media (max-width: 1069px) {
    .card {
        margin-top: 112px;
    }

    .custom-form .form-control {
        margin-bottom: 3px;
    }
}

@media (max-width: 991px) {
    .card {
        margin-top: 69px;
    }
}

@media (max-width: 959px) {
    .card {
        margin-top: 106px;
    }
}

@media (max-width: 725px) {
    .card {
        margin-top: 138px;
    }
}

@media (max-width: 491px) {
    .card {
        margin-top: 242px;
    }
}

@media (min-width : 375px) and (max-width: 491px) {
    .custom-form .form-control {
        max-width: 159px;
    }

    .card {
        margin-top: 138px;
    }

    .advance-filter .form-control:nth-child(even) {
        margin-right: 0;
    }

}

@media (max-width : 341px) {
    .card {
        margin-top: 278px;
    }
} */

.yellow {
    color: #e3da2e;
}

.textorange {
    color: #FF9E0C;
}

.yellowbg {
    background-color: #e3da2e;
}

.bgorange {
    background-color: #FF9E0C;
}

.p-datatable .p-datatable-tbody>tr>td {
    height: 55px;
}

.refresh-btn {
    position: absolute;
    right: 6px;
    top: 5px;
    z-index: 99;
}

.highlighted-message {
    background-color: #ffcccb;
    /* or any other highlighting style */
}

.p-divider-content {
    color: #1B3D6D;
    font-weight: 500 !important;
}

.p-divider-horizontal:before {
    border-color: #1B3D6D !important;
}

.custom-multi-select-item {
    display: flex;
    align-items: center;
}



@-webkit-keyframes animate-svg-stroke-1 {
    0% {
        stroke-dashoffset: 1061.25927734375px;
        stroke-dasharray: 1061.25927734375px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 1061.25927734375px;
    }
}

@keyframes animate-svg-stroke-1 {
    0% {
        stroke-dashoffset: 1061.25927734375px;
        stroke-dasharray: 1061.25927734375px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 1061.25927734375px;
    }
}

@-webkit-keyframes animate-svg-fill-1 {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(20, 60, 108);
    }
}

@keyframes animate-svg-fill-1 {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(20, 60, 108);
    }
}

.svg-elem-1 {
    -webkit-animation: animate-svg-stroke-1 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0s both,
        animate-svg-fill-1 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s both;
    animation: animate-svg-stroke-1 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0s both,
        animate-svg-fill-1 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s both;
}

@-webkit-keyframes animate-svg-stroke-2 {
    0% {
        stroke-dashoffset: 266.92236328125px;
        stroke-dasharray: 266.92236328125px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 266.92236328125px;
    }
}

@keyframes animate-svg-stroke-2 {
    0% {
        stroke-dashoffset: 266.92236328125px;
        stroke-dasharray: 266.92236328125px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 266.92236328125px;
    }
}

@-webkit-keyframes animate-svg-fill-2 {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(20, 60, 108);
    }
}

@keyframes animate-svg-fill-2 {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(20, 60, 108);
    }
}

.svg-elem-2 {
    -webkit-animation: animate-svg-stroke-2 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s both,
        animate-svg-fill-2 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s both;
    animation: animate-svg-stroke-2 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s both,
        animate-svg-fill-2 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s both;
}

@-webkit-keyframes animate-svg-stroke-3 {
    0% {
        stroke-dashoffset: 2359.078857421875px;
        stroke-dasharray: 2359.078857421875px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 2359.078857421875px;
    }
}

@keyframes animate-svg-stroke-3 {
    0% {
        stroke-dashoffset: 2359.078857421875px;
        stroke-dasharray: 2359.078857421875px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 2359.078857421875px;
    }
}

@-webkit-keyframes animate-svg-fill-3 {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(20, 60, 108);
    }
}

@keyframes animate-svg-fill-3 {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(20, 60, 108);
    }
}

.svg-elem-3 {
    -webkit-animation: animate-svg-stroke-3 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s both,
        animate-svg-fill-3 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1s both;
    animation: animate-svg-stroke-3 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s both,
        animate-svg-fill-3 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1s both;
}

@-webkit-keyframes animate-svg-stroke-4 {
    0% {
        stroke-dashoffset: 157.4320831298828px;
        stroke-dasharray: 157.4320831298828px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 157.4320831298828px;
    }
}

@keyframes animate-svg-stroke-4 {
    0% {
        stroke-dashoffset: 157.4320831298828px;
        stroke-dasharray: 157.4320831298828px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 157.4320831298828px;
    }
}

@-webkit-keyframes animate-svg-fill-4 {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(20, 60, 108);
    }
}

@keyframes animate-svg-fill-4 {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(20, 60, 108);
    }
}

.svg-elem-4 {
    -webkit-animation: animate-svg-stroke-4 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s both,
        animate-svg-fill-4 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s both;
    animation: animate-svg-stroke-4 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s both,
        animate-svg-fill-4 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s both;
}

@-webkit-keyframes animate-svg-stroke-5 {
    0% {
        stroke-dashoffset: 89.60336303710938px;
        stroke-dasharray: 89.60336303710938px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 89.60336303710938px;
    }
}

@keyframes animate-svg-stroke-5 {
    0% {
        stroke-dashoffset: 89.60336303710938px;
        stroke-dasharray: 89.60336303710938px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 89.60336303710938px;
    }
}

@-webkit-keyframes animate-svg-fill-5 {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(20, 60, 108);
    }
}

@keyframes animate-svg-fill-5 {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(20, 60, 108);
    }
}

.svg-elem-5 {
    -webkit-animation: animate-svg-stroke-5 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s both,
        animate-svg-fill-5 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2000000000000002s both;
    animation: animate-svg-stroke-5 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s both,
        animate-svg-fill-5 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2000000000000002s both;
}

@-webkit-keyframes animate-svg-stroke-6 {
    0% {
        stroke-dashoffset: 573.890869140625px;
        stroke-dasharray: 573.890869140625px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 573.890869140625px;
    }
}

@keyframes animate-svg-stroke-6 {
    0% {
        stroke-dashoffset: 573.890869140625px;
        stroke-dasharray: 573.890869140625px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 573.890869140625px;
    }
}

@-webkit-keyframes animate-svg-fill-6 {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(188, 28, 44);
    }
}

@keyframes animate-svg-fill-6 {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(188, 28, 44);
    }
}

.svg-elem-6 {
    -webkit-animation: animate-svg-stroke-6 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s both,
        animate-svg-fill-6 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3s both;
    animation: animate-svg-stroke-6 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s both,
        animate-svg-fill-6 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3s both;
}

@-webkit-keyframes animate-svg-stroke-7 {
    0% {
        stroke-dashoffset: 155.4101104736328px;
        stroke-dasharray: 155.4101104736328px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 155.4101104736328px;
    }
}

@keyframes animate-svg-stroke-7 {
    0% {
        stroke-dashoffset: 155.4101104736328px;
        stroke-dasharray: 155.4101104736328px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 155.4101104736328px;
    }
}

@-webkit-keyframes animate-svg-fill-7 {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(20, 60, 108);
    }
}

@keyframes animate-svg-fill-7 {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(20, 60, 108);
    }
}

.svg-elem-7 {
    -webkit-animation: animate-svg-stroke-7 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.72s both,
        animate-svg-fill-7 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.4000000000000001s both;
    animation: animate-svg-stroke-7 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.72s both,
        animate-svg-fill-7 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.4000000000000001s both;
}

@-webkit-keyframes animate-svg-stroke-8 {
    0% {
        stroke-dashoffset: 112.64246368408203px;
        stroke-dasharray: 112.64246368408203px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 112.64246368408203px;
    }
}

@keyframes animate-svg-stroke-8 {
    0% {
        stroke-dashoffset: 112.64246368408203px;
        stroke-dasharray: 112.64246368408203px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 112.64246368408203px;
    }
}

@-webkit-keyframes animate-svg-fill-8 {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(20, 60, 108);
    }
}

@keyframes animate-svg-fill-8 {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(20, 60, 108);
    }
}

.svg-elem-8 {
    -webkit-animation: animate-svg-stroke-8 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.84s both,
        animate-svg-fill-8 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.5s both;
    animation: animate-svg-stroke-8 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.84s both,
        animate-svg-fill-8 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.5s both;
}

@-webkit-keyframes animate-svg-stroke-9 {
    0% {
        stroke-dashoffset: 205.27833557128906px;
        stroke-dasharray: 205.27833557128906px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 205.27833557128906px;
    }
}

@keyframes animate-svg-stroke-9 {
    0% {
        stroke-dashoffset: 205.27833557128906px;
        stroke-dasharray: 205.27833557128906px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 205.27833557128906px;
    }
}

@-webkit-keyframes animate-svg-fill-9 {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(20, 60, 108);
    }
}

@keyframes animate-svg-fill-9 {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(20, 60, 108);
    }
}

.svg-elem-9 {
    -webkit-animation: animate-svg-stroke-9 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.96s both,
        animate-svg-fill-9 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.6s both;
    animation: animate-svg-stroke-9 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.96s both,
        animate-svg-fill-9 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.6s both;
}

@-webkit-keyframes animate-svg-stroke-10 {
    0% {
        stroke-dashoffset: 218.02667236328125px;
        stroke-dasharray: 218.02667236328125px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 218.02667236328125px;
    }
}

@keyframes animate-svg-stroke-10 {
    0% {
        stroke-dashoffset: 218.02667236328125px;
        stroke-dasharray: 218.02667236328125px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 218.02667236328125px;
    }
}

@-webkit-keyframes animate-svg-fill-10 {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(188, 28, 44);
    }
}

@keyframes animate-svg-fill-10 {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(188, 28, 44);
    }
}

.svg-elem-10 {
    -webkit-animation: animate-svg-stroke-10 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 1.08s both,
        animate-svg-fill-10 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7000000000000002s both;
    animation: animate-svg-stroke-10 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 1.08s both,
        animate-svg-fill-10 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7000000000000002s both;
}

@-webkit-keyframes animate-svg-stroke-11 {
    0% {
        stroke-dashoffset: 252.34402465820312px;
        stroke-dasharray: 252.34402465820312px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 252.34402465820312px;
    }
}

@keyframes animate-svg-stroke-11 {
    0% {
        stroke-dashoffset: 252.34402465820312px;
        stroke-dasharray: 252.34402465820312px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 252.34402465820312px;
    }
}

@-webkit-keyframes animate-svg-fill-11 {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(20, 60, 108);
    }
}

@keyframes animate-svg-fill-11 {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(20, 60, 108);
    }
}

.svg-elem-11 {
    -webkit-animation: animate-svg-stroke-11 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2s both,
        animate-svg-fill-11 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.8s both;
    animation: animate-svg-stroke-11 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2s both,
        animate-svg-fill-11 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.8s both;
}

@-webkit-keyframes animate-svg-stroke-12 {
    0% {
        stroke-dashoffset: 107.23974609375px;
        stroke-dasharray: 107.23974609375px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 107.23974609375px;
    }
}

@keyframes animate-svg-stroke-12 {
    0% {
        stroke-dashoffset: 107.23974609375px;
        stroke-dasharray: 107.23974609375px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 107.23974609375px;
    }
}

@-webkit-keyframes animate-svg-fill-12 {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(20, 60, 108);
    }
}

@keyframes animate-svg-fill-12 {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(20, 60, 108);
    }
}

.svg-elem-12 {
    -webkit-animation: animate-svg-stroke-12 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3199999999999998s both,
        animate-svg-fill-12 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.9000000000000001s both;
    animation: animate-svg-stroke-12 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3199999999999998s both,
        animate-svg-fill-12 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.9000000000000001s both;
}

@-webkit-keyframes animate-svg-stroke-13 {
    0% {
        stroke-dashoffset: 259.11553955078125px;
        stroke-dasharray: 259.11553955078125px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 259.11553955078125px;
    }
}

@keyframes animate-svg-stroke-13 {
    0% {
        stroke-dashoffset: 259.11553955078125px;
        stroke-dasharray: 259.11553955078125px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 259.11553955078125px;
    }
}

@-webkit-keyframes animate-svg-fill-13 {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(20, 60, 108);
    }
}

@keyframes animate-svg-fill-13 {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(20, 60, 108);
    }
}

.svg-elem-13 {
    -webkit-animation: animate-svg-stroke-13 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 1.44s both,
        animate-svg-fill-13 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2s both;
    animation: animate-svg-stroke-13 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 1.44s both,
        animate-svg-fill-13 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2s both;
}

@-webkit-keyframes animate-svg-stroke-14 {
    0% {
        stroke-dashoffset: 139.23739624023438px;
        stroke-dasharray: 139.23739624023438px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 139.23739624023438px;
    }
}

@keyframes animate-svg-stroke-14 {
    0% {
        stroke-dashoffset: 139.23739624023438px;
        stroke-dasharray: 139.23739624023438px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 139.23739624023438px;
    }
}

@-webkit-keyframes animate-svg-fill-14 {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(20, 60, 108);
    }
}

@keyframes animate-svg-fill-14 {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(20, 60, 108);
    }
}

.svg-elem-14 {
    -webkit-animation: animate-svg-stroke-14 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 1.56s both,
        animate-svg-fill-14 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.1s both;
    animation: animate-svg-stroke-14 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 1.56s both,
        animate-svg-fill-14 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.1s both;
}

@-webkit-keyframes animate-svg-stroke-15 {
    0% {
        stroke-dashoffset: 200.56668090820312px;
        stroke-dasharray: 200.56668090820312px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 200.56668090820312px;
    }
}

@keyframes animate-svg-stroke-15 {
    0% {
        stroke-dashoffset: 200.56668090820312px;
        stroke-dasharray: 200.56668090820312px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 200.56668090820312px;
    }
}

@-webkit-keyframes animate-svg-fill-15 {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(20, 60, 108);
    }
}

@keyframes animate-svg-fill-15 {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(20, 60, 108);
    }
}

.svg-elem-15 {
    -webkit-animation: animate-svg-stroke-15 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 1.68s both,
        animate-svg-fill-15 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.2s both;
    animation: animate-svg-stroke-15 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 1.68s both,
        animate-svg-fill-15 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.2s both;
}

@-webkit-keyframes animate-svg-stroke-16 {
    0% {
        stroke-dashoffset: 193.66567993164062px;
        stroke-dasharray: 193.66567993164062px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 193.66567993164062px;
    }
}

@keyframes animate-svg-stroke-16 {
    0% {
        stroke-dashoffset: 193.66567993164062px;
        stroke-dasharray: 193.66567993164062px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 193.66567993164062px;
    }
}

@-webkit-keyframes animate-svg-fill-16 {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(20, 60, 108);
    }
}

@keyframes animate-svg-fill-16 {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(20, 60, 108);
    }
}

.svg-elem-16 {
    -webkit-animation: animate-svg-stroke-16 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7999999999999998s both,
        animate-svg-fill-16 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.3s both;
    animation: animate-svg-stroke-16 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7999999999999998s both,
        animate-svg-fill-16 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.3s both;
}

@-webkit-keyframes animate-svg-stroke-17 {
    0% {
        stroke-dashoffset: 168.84829711914062px;
        stroke-dasharray: 168.84829711914062px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 168.84829711914062px;
    }
}

@keyframes animate-svg-stroke-17 {
    0% {
        stroke-dashoffset: 168.84829711914062px;
        stroke-dasharray: 168.84829711914062px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 168.84829711914062px;
    }
}

@-webkit-keyframes animate-svg-fill-17 {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(20, 60, 108);
    }
}

@keyframes animate-svg-fill-17 {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(20, 60, 108);
    }
}

.svg-elem-17 {
    -webkit-animation: animate-svg-stroke-17 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 1.92s both,
        animate-svg-fill-17 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.4000000000000004s both;
    animation: animate-svg-stroke-17 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 1.92s both,
        animate-svg-fill-17 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.4000000000000004s both;
}

@-webkit-keyframes animate-svg-stroke-18 {
    0% {
        stroke-dashoffset: 194.37820434570312px;
        stroke-dasharray: 194.37820434570312px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 194.37820434570312px;
    }
}

@keyframes animate-svg-stroke-18 {
    0% {
        stroke-dashoffset: 194.37820434570312px;
        stroke-dasharray: 194.37820434570312px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 194.37820434570312px;
    }
}

@-webkit-keyframes animate-svg-fill-18 {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(188, 28, 44);
    }
}

@keyframes animate-svg-fill-18 {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(188, 28, 44);
    }
}

.svg-elem-18 {
    -webkit-animation: animate-svg-stroke-18 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 2.04s both,
        animate-svg-fill-18 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.5s both;
    animation: animate-svg-stroke-18 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 2.04s both,
        animate-svg-fill-18 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.5s both;
}

@-webkit-keyframes animate-svg-stroke-19 {
    0% {
        stroke-dashoffset: 102.6219253540039px;
        stroke-dasharray: 102.6219253540039px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 102.6219253540039px;
    }
}

@keyframes animate-svg-stroke-19 {
    0% {
        stroke-dashoffset: 102.6219253540039px;
        stroke-dasharray: 102.6219253540039px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 102.6219253540039px;
    }
}

@-webkit-keyframes animate-svg-fill-19 {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(188, 28, 44);
    }
}

@keyframes animate-svg-fill-19 {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(188, 28, 44);
    }
}

.svg-elem-19 {
    -webkit-animation: animate-svg-stroke-19 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 2.16s both,
        animate-svg-fill-19 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.6s both;
    animation: animate-svg-stroke-19 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 2.16s both,
        animate-svg-fill-19 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.6s both;
}

@-webkit-keyframes animate-svg-stroke-20 {
    0% {
        stroke-dashoffset: 200.54783630371094px;
        stroke-dasharray: 200.54783630371094px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 200.54783630371094px;
    }
}

@keyframes animate-svg-stroke-20 {
    0% {
        stroke-dashoffset: 200.54783630371094px;
        stroke-dasharray: 200.54783630371094px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 200.54783630371094px;
    }
}

@-webkit-keyframes animate-svg-fill-20 {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(188, 28, 44);
    }
}

@keyframes animate-svg-fill-20 {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(188, 28, 44);
    }
}

.svg-elem-20 {
    -webkit-animation: animate-svg-stroke-20 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 2.28s both,
        animate-svg-fill-20 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.7s both;
    animation: animate-svg-stroke-20 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 2.28s both,
        animate-svg-fill-20 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.7s both;
}

@-webkit-keyframes animate-svg-stroke-21 {
    0% {
        stroke-dashoffset: 168.81680297851562px;
        stroke-dasharray: 168.81680297851562px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 168.81680297851562px;
    }
}

@keyframes animate-svg-stroke-21 {
    0% {
        stroke-dashoffset: 168.81680297851562px;
        stroke-dasharray: 168.81680297851562px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 168.81680297851562px;
    }
}

@-webkit-keyframes animate-svg-fill-21 {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(20, 60, 108);
    }
}

@keyframes animate-svg-fill-21 {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(20, 60, 108);
    }
}

.svg-elem-21 {
    -webkit-animation: animate-svg-stroke-21 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 2.4s both,
        animate-svg-fill-21 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.8s both;
    animation: animate-svg-stroke-21 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 2.4s both,
        animate-svg-fill-21 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.8s both;
}

@-webkit-keyframes animate-svg-stroke-22 {
    0% {
        stroke-dashoffset: 165.91392517089844px;
        stroke-dasharray: 165.91392517089844px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 165.91392517089844px;
    }
}

@keyframes animate-svg-stroke-22 {
    0% {
        stroke-dashoffset: 165.91392517089844px;
        stroke-dasharray: 165.91392517089844px;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 165.91392517089844px;
    }
}

@-webkit-keyframes animate-svg-fill-22 {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(20, 60, 108);
    }
}

@keyframes animate-svg-fill-22 {
    0% {
        fill: transparent;
    }

    100% {
        fill: rgb(20, 60, 108);
    }
}

.svg-elem-22 {
    -webkit-animation: animate-svg-stroke-22 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 2.52s both,
        animate-svg-fill-22 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.9000000000000004s both;
    animation: animate-svg-stroke-22 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 2.52s both,
        animate-svg-fill-22 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.9000000000000004s both;
}

.referral {
    margin: 0;
}

.task-modal p {
    margin: 0;
    font-size: 13px;
}

.title {
    font-size: 16px;
    font-weight: bold;
}

.chat {
    fill: #1B3D6D !important;
}

.global-filter {
    max-width: unset !important;

}

.task-modal .modal-footer,
.task-modal .modal-header {
    background-color: #F5F5F5;
}

.CircularProgressbar .CircularProgressbar-text {
    fill: #1B3D6D !important;
}

.CircularProgressbar .CircularProgressbar-path {
    stroke: #1B3D6D !important;
}

/* .advance-filter .show-filterlist {
    -webkit-transition: max-height 1.5s;
    -moz-transition: max-height 1.5s;
    -ms-transition: max-height 1.5s;
    -o-transition: max-height 1.5s;
    transition: max-height 1.5s;
    overflow: hidden;
    max-height: 0;
}

.advance-filter.show .show-filterlist {
    max-height: 300px;
} */
/* .newstartfilter .show-filterlist{
  
    margin-top: 15px!important;
} */

.animation-duration-500 {
    animation-duration: 500ms !important;
}

.fadein {
    animation: fadein 0.15s linear !important;
    animation-duration: 500ms !important;
    opacity: 1;
}

.custom-icon-width {
    width: 22px;
    cursor: pointer;
    color: #234576;
}



.custom-form .star-icon {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: -14px;   
    

}


.message-timestamp {
    font-size: 12px;

    display: block;
    margin-top: 5px;
    color: #1F1F1F;

  }





@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/* @media (max-width:1799px) {
    .card {
        margin-top: 85px;
    }
} */

@media (max-width:676px) {
    .row {
        --bs-gutter-x: 0.5rem;}

    .card {
        /* old: 135px; */
        margin-top: 175px;
    }
    .custom-form .star-icon {
        cursor: pointer;
        position: absolute;
        right: -3px;
        top: -41px;
    }
}

@media (max-width:425px) {
    .card {
        margin-top: 205px;
    }

}

@media (min-width: 675px) and (max-width: 996px) {
    .row {
        --bs-gutter-x: 0.5rem;}
    .custom-form .star-icon {
        cursor: pointer;
        position: absolute;
        right: -3px;
        top: -41px;
    }
}

@media (min-width: 675px) and (max-width: 1300px) {
    .custom-padding {
        margin-top: 130px;
    }

}



.p-multiselect-items-wrapper,
.p-multiselect-items {
    /* width: auto !important; */
    width: 100% !important;
    background-color: #fff !important;
    box-shadow: none !important;
    /* overflow: hidden !important; */

}

.p-multiselect-panel {
    width: fit-content !important;
    overflow-y: auto !important;
    /* max-height: 200px !important; */
    /* min-width: fit-content !important; */
}

.p-multiselect-item {
    width: 100% !important;
}

.p-component-overlay {
    top: 0 !important;
}

.introjs-tooltiptext,
.introjs-tooltiptext p {
    font-family: 'Josefin Sans', sans-serif !important;
    padding: 0 10px !important;
}

.introjs-skipbutton {
    font-size: 41px;
    font-weight: normal;
}

.introjs-tooltip {
    min-width: 400px !important;
    max-width: 400px !important;
}

p:has(.download-icon) {
    text-align: center;
}

.p-dropdown {
    align-items: center !important;
}

.bg-yellow {
    background-color: #e3da2e !important;
}

.bg-blue {
    background-color: #0000ff !important;
}

.p-autocomplete-input,
.custom-date .p-inputtext {
    border: none !important;
    width: 100% !important;
    background-color: transparent !important;
    padding: 0 0 0 40px !important;
}

.custom-date .p-inputtext {
    padding: 0 0 0 5px !important;
}

.p-autocomplete-input:focus,
.custom-date .p-inputtext:focus {
    box-shadow: none !important;
}

.suggestion-filter {
    padding: 0 !important;
}



.search-icon {
    z-index: 99;
    margin-top: -2px;
}

.p-multiselect-filter {
    padding: 0.2rem 0.5rem !important;
}

.custom-red {
    border-color: red !important;
}

.checkbox-group:last-child {
    position: absolute;
    bottom: 16px;
    left: 180px;
    width: 100%;
    right: 0;

}

.checkbox-group:last-child .check-list {
    display: inline-flex;
}

.checkbox-group:last-child .custom-list {
    width: 187px;
}

/* .checkbox-group:last-child .taskdata {
    margin-left: 27px;
} */

.checkbox-group:last-child .custom-time {
    margin-left: -8px;
}

.single-line {
    display: flex;
}

.reset-btn {
    margin-top: -5px;
}

.email-form .form-control {
    max-width: 100%;
}

.grey-icon {
    color: grey;
}

.custom-chips {
    position: relative;
}

.custom-chips .p-inputtext {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #B4C5DD;
    background: #FFF;
    color: rgba(96, 96, 96, 0.80);
    font-size: 14px;
    font-weight: 400;
    height: 34px;
    align-items: center;
    display: flex;
    margin-right: 10px;
    width: 100%;

}

.p-chips-input-token,
.p-chips-token {
    height: 100% !important;
}

.error {
    font-size: 13px;
}

.new-ref {
    position: absolute;
    right: 0;
    top: 0;
}


.upload-btn .p-button {
    background: #1B3D6D;
    padding: 5px 16px;
}

.physician-form .form-control {
    margin-bottom: 0;
}

.modal .modal-dialog.referral-modal {
    max-width: 500px;
}




.custom-upload {
    display: none;
    /* Hide the default file input */
}

.custom-upload-label {
    display: inline-block;
    padding: 5px 20px;
    background-color: transparent;
    color: #1B3D6D;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    border: 1px dashed #1B3D6D;
}

.p-dialog-mask {
    z-index: 99 !important;
}


/* Ensure the label is styled to look like a button */
.custom-upload-label:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, .5);
}

.custom-file {
    display: flex;
    align-items: center;
}

.file-upload {
    display: flex;
    align-items: center;
    margin-top: 30px;
    /* justify-content: center; */
}

.file-name {
    font-size: 14px;
    color: #333;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F1F2F3;
    padding: 5px;
    border-radius: 6px;
    position: relative;
}

.file-name img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.file-name svg {
    position: absolute;
    top: -6px;
    right: -6px;
    width: 14px;
    height: 14px;
    background: #A1A1A1;
    border-radius: 50%;
    color: #fff;
}

.physician-table.custom-padding {
    margin-top: 40px;
}

.p-datepicker {
    z-index: 9999 !important;
}

.custom-calendar {
    width: 100%;
}

.custom-calendar .p-inputtext {
    border-radius: 5px;
    border: 1px solid #B4C5DD;
    background: #FFF;
    color: rgba(96, 96, 96, 0.80);
    font-size: 14px;
    font-weight: 400;
    height: 34px;
    align-items: center;
    display: flex;
}

.custom-calendar .p-button {
    padding: 0 !important;
    background: #1b3d6d;
    border-color: #1b3d6d !important;
}

.patient-exists h5 {
    font-weight: 700;
}

.patient-exists p {
    margin-bottom: 5px;
}

.doucment-table .p-datatable-tbody>tr>td {
    border-width: 0 0 1px 0 !important;
}

.p-datatable .p-paginator-bottom {
    border-width: 1px 0 0px 0 !important;
}


.p-dropdown-panel {
    z-index: 99999 !important;
}

.modal .modal-dialog.referral-modal {
    max-width: 1000px;
}

.modal .modal-dialog.delete-modal {
    max-width: 500px;
}

.create-mrn {
    text-decoration: underline;
    cursor: pointer;
    color: #0a3e71;
}


.custom-tab .p-tabview-panels {
    padding: 0 !important;
}

.custom-tab .p-tabview-nav-container {
    margin-bottom: 10px;
}

.custom-tab>span {
    border-left: 1px solid #ccdae4;
    padding: 10px;
    display: inline-block;
    margin-left: 10px;
}

.p-tabview .custom-tab {
    padding: 0 10px;
}

.custom-tab.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    box-shadow: none;
}

.custom-tab.p-tabview .p-tabview-nav {
    border-bottom: none;
    display: flex;
    justify-content: center;
}

.custom-tab.p-tabview .p-tabview-nav li {
    margin-right: 20px;
    /* border: 1px solid #ccdae4; */
    /* border-radius: 15px; */
}

.custom-tab.p-tabview .p-tabview-nav li .p-tabview-nav-link {
    border: 1px solid #ccdae4;
    margin: unset;
    border-radius: 6px;
    padding: 0;
}


.custom-tab.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: #1b3d6d;
    border-color: #1b3d6d;
    color: #ffff;
}
.p-datatable-wrapper{
    overflow-x: scroll !important;
}

/* 
@media (max-height: 1550px) {
    body {
        overflow-y: hidden !important;
    }

    .expiring-auth .p-datatable-wrapper {
        max-height: 650px !important;
    }
}

@media (max-height: 900px) {
    body {
        overflow-y: hidden !important;
    }

    .expiring-auth .p-datatable-wrapper {
        max-height: 350px !important;
    }
} */

@media (max-height: 720px) {
    body {
        overflow-y: scroll !important;
    }
}


.disabled {
    background-color: #e0e0e0;
    color: #1b3d6d !important;
    cursor: not-allowed;
    opacity: .65;
}
.disabled:hover {
    background-color: #e0e0e0;
    color: #1b3d6d !important;
}
.custom-textarea {
    height: 100px !important;
    resize: none !important;
}


.pending-status-modal {
    background: #FFF;
    overflow: hidden;
}

.pending-status-modal .p-dialog-header {
    display: flex;
    align-items: center;
    padding: 8px 6px !important;
    border-bottom: 1px solid #B4C5DD !important;
}

.pending-status-modal .p-dialog-header h3 {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Josefin Sans', sans-serif;

}

.pending-status-modal .p-dialog-title {
    display: flex;
    align-items: center;
}

.pending-status-modal .p-dialog-content {
    padding: 15px 15px 20px 15px !important;
}

@media (max-width:767px) {
    .pending-status-modal .p-dialog-content {
        padding: 10px 8px 10px 8px !important;
    }

    .chat-title {
        min-height: 65% !important;
        max-height: 65% !important;
        margin: 10px;
    }
    .modal .modal-dialog.updatetask-modal {
        max-width: 100% !important;
        width: auto !important;
    }
}

.common-radio .p-radiobutton-box {
    border: 1px solid #1b3d6d !important;
}

.common-radio .p-radiobutton-box:hover {
    border-color: #1b3d6d !important;
}

.common-radio .p-highlight {
    box-shadow: none !important;
    border-color: #1b3d6d !important;
    background-color: #1b3d6d !important;
}

.common-radio .p-highlight .p-radiobutton-icon {
    background-color: #1b3d6d !important;
}


.modal .modal-dialog.updatetask-modal {
    max-width: 100%;
    width: 1200px;
}
.updatetask-modal {
    font-family: 'Josefin Sans', sans-serif !important;
}
.updatetask-modal.p-dialog .p-dialog-header {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    padding: 10px 20px;
    border-bottom: 1x solid #d9d9d9 !important;
    font-family: 'Josefin Sans', sans-serif;
}
.title-task {
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 600;
    font-size: 18px;

}
.updatetask-modal.p-dialog .p-dialog-content {
   
    border-top: 1x solid #d9d9d9 !important;
}
.header-table {
    background-color: #f3f8ff;
    padding: 5px;
    margin-bottom: 10px;
}
/* .custom-radio.p-radiobutton .p-radiobutton-box {
    border-radius: 0 !important;
} */
.task-details p {
    font-size: 16px;
    font-weight: 500;
}
.section h4 {
    font-size: 16px;
    font-weight: 600;
}
/* .custom-radio-group label {
    font-size: 14px;
    line-height: 14px;
}
.custom-radio.p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: #1b3d6d  !important;
    background: #1b3d6d !important;
}

.custom-radio.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
    background-color: #1b3d6d;
} */

/* .p-dialog.updatetask-modal {
    min-height: unset !important; 
    max-height: unset !important;
} */
 .updatetask-modal .p-dialog-content {
    padding-bottom: 0 !important;
 }

 .userdetails {
    font-size: 12px;
    line-height: 14px;
    color: #99A0A8;
    display: flex;
    align-items: center;
 }
.sub-userdetails {
    display: inline-block;
}
.intake-sub-userdetails {
    display: block !important; /* Overrides to display block only for intake-sub-userdetails */
}
 /* .custom-radio-group .p-checkbox .p-checkbox-box .p-checkbox-icon {
    color: #1b3d6d;
    display: none;
}



.custom-radio-group .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover, .custom-radio-group .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight {
    border-color: #1b3d6d;
    background: #1b3d6d;
    border-radius: 0;
}

.custom-radio-group .p-checkbox .p-checkbox-box {
    border-color: #000;
    border-radius: 0;
    width: 18px;
    height: 18px;
}


/* Style for custom radio button */
.custom-radio {
    appearance: none !important; /* Remove default radio button appearance */
    -webkit-appearance: none !important; 
    -moz-appearance: none !important;
    border-radius: 50% !important; /* Round shape */
    width: 20px !important;
    height: 20px !important;
    display: inline-block !important;
    position: relative !important;
    background-color: white !important;
    cursor: pointer !important;
    transition: all 0.3s ease !important;
}

/* Style for checked radio button */
.custom-radio:checked {
    background-color: #1b3d6d !important;
    border-color: #28a745 !important; /* Green border */
}

/* Add a check mark icon inside the radio button */
.custom-radio:checked::before {
    content: '✓' !important; /* Unicode for a check mark */
    position: absolute !important;
    top: 0 !important;
    left: 4px !important; /* Adjust the position of the check mark */
    font-size: 14px !important; /* Adjust the size */
    color: white !important;
}

/* Tooltip styling */
.custom-radio + label {
    cursor: pointer !important;
    display: inline-block !important;
    position: relative !important;
    margin-left: 10px !important;
    font-size: 14px !important;
}

/* Custom styling for p-checkbox */
.p-checkbox .p-checkbox-box .p-checkbox-icon {
    transition-duration: 0.1s;
    color: #ffffff;
    font-size: 14px;
    background-color: #1b3d6d !important;
}

/* Remove border and background for highlighted state in checkbox */
.p-checkbox .p-checkbox-box.p-highlight {
    border-color: #1b3d6d !important;       /* Set the border color */
    background-color: #1b3d6d !important;   /* Set the background color */
}

/* Remove outline, box-shadow, and border-color for focus state */
.p-checkbox .p-checkbox-box:focus {
    outline: none !important; /* Remove outline */
    border-color: #1b3d6d !important; /* Keep border dark blue */
}

/* Remove hover color for .custom-radio */
.custom-radio:hover {
    background-color: white !important;
    border-color: #1b3d6d !important;
}

/* Remove hover color for .p-checkbox */
.p-checkbox .p-checkbox-box:hover {
    background-color: #1b3d6d !important;
    border-color: #1b3d6d !important;
}
/* Remove focus styles for the checkbox */

/* Label tooltip styling */
[data-pr-tooltip] {
    display: inline-block !important;
}

.inner-chat.left-messages p svg {
    width: 68px;
    height: 56px;
    text-align: center;
    margin-left: 12px
}
.custom-radio-group .p-checkbox .p-checkbox-box.p-disabled {
    border-color: #808080a3;
    background: #808080a3;
}

.updatetask-modal .p-radiobutton .p-radiobutton-box {
    width: 16px;
    height: 16px;
}
.not-found {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
}

.p-button.p-button-outlined.not-found-btn {
    color: #000;
    padding: 5px 10px;
}
.not-found img {
    opacity: 0.3;
}

.referral p, .task-details p {
    font-size: 14px;
}
.toast  {
    border: 0;
}

.right-chat p.document-msg {
    background-color: transparent;
    
    border-color: transparent;
    box-shadow: none;
    padding: 0;
    margin: 0;
}
.right-chat p.document-msg::before {
    border-bottom: 20px solid transparent;
}
.system-msg {
    text-decoration: none;
    font-size: 14px;
    line-height: 14px;
}
 .document-msg a {
    color: #1b3d6d;
    margin-top: -4px;
 }
 .document-icon {
    margin-top: -4px;
 }

 .patient-chart {
    overflow: visible;
    color: #00BFFF;
    cursor: pointer;
    text-decoration: underline;
    white-space: nowrap;
    position: absolute;
    left: 109%;
    top: 39px;
    margin-left: 10px;
 }
.home-redirection {
    text-decoration: none;
}
.close-icon {
    cursor: pointer;
    width: 24px;
    height: 24px;
    color: #333;
}
.delete-icon-filter {
    width: 25px;
    height: 20px;
    margin-top: 0;
    cursor: pointer;
    color: black;
}